import React from "react";

import './footer-contact-info.styles.scss';

const FooterContactInfo = () => (
    <div className="context-text-widget">
    <h1 className="contact-header">İletişim</h1>
        <p className="contact-text">Softtech<br />
            <u>İstanbul Merkez Ofis</u><br />
            İTÜ Ayazağa Kampüsü Reşitpaşa Mah. Katar Cad. İTÜ Teknokent Arı-3 No:4 Kat:4-5 34469 Maslak – Sarıyer<br/>
            <u>Tel</u>: +90 850 290 6000<br/>
            <u>Faks</u>: +90 212 328 1199<br/>
            <u>E-posta</u>: info@softtech.com.tr
        </p>
    </div>
);

export default FooterContactInfo;