import { ReactComponent as Suna } from '../../../../assets/suna.svg'
import { ReactComponent as Stick } from '../../../../assets/blue-stick.svg';

import './hero.styles.scss'

const Hero = () => (
    <div className="initial-passage">
        <Stick className="stick" />
        <p className="initial-text">Uygulamamız ismini suna ördeğinden alır. Yaşam alanı olarak sulak alanları tercih eden ve temiz suya ihtiyaç duyan, beyaz ağırlıklı gövdesi sayesinde kolayca tanınan iri bir ördektir.</p>
        <Suna className='suna-image' />
    </div>
);

export default Hero;