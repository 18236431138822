import React from "react";

import './benefit-content.styles.scss';

import SunaUI from '../../assets/suna_ui.png';
import {ReactComponent as Sustainability} from '../../assets/sustainability.svg';
import {ReactComponent as WaterLeak} from '../../assets/water-leak.svg';
import {ReactComponent as WaterDrop} from '../../assets/water-drop.svg';
import {ReactComponent as FightThirst} from '../../assets/fight-thirst.svg';
import {ReactComponent as WaterConsumption} from '../../assets/consumption.svg';
import {ReactComponent as Bill} from '../../assets/bill.svg';
import Container from '../../components/container/container';

const BenefitContent = () => (
    <div className='benefit-content'>
        <Container>
        <p className='title'>SUNA’nın Faydaları Nelerdir?</p>
        <img className='main-image' alt='suna-ui' src={SunaUI} />
        <div className='benefit-list'>
            <div className='benefit-item'>
                <div className='image-container'>
                    <Bill className='image' />
                </div>
                <div className='description'>
                    <p className='title'>Daha Düşük Fatura Ödersin</p>
                    <span className='text'>Aylık ortalama su kullanımını kontrol altına alıp daha az fatura ücreti ödeyebilirsin.</span>
                </div>
            </div>
            <div className='benefit-item'>
                <div className='image-container'>
                    <WaterDrop className='image' />
                </div>
                <div className='description'>
                    <p className='title'>Damla Puan Kazanırsın</p>
                    <span className='text'>Uygulama içerisindeki görevleri yerine getirerek damla puan kazanabilirsin.</span>
                </div>
            </div>
            <div className='benefit-item'>
                <div className='image-container'>
                    <FightThirst className='image' />
                </div>
                <div className='description'>
                    <p className='title'>Susuzlukla Mücadeleye Katkıda Bulunursun</p>
                    <span className='text'>Su tüketimini azaltarak kaynaklarımızın daha etkin kullanımına katıda bulunabilirsin.</span>
                </div>
            </div>
            <div className='benefit-item'>
                <div className='image-container'>
                    <Sustainability className='image' />
                </div>
                <div className='description'>
                    <p className='title'>Sürdürülebilir Yaşama Katkıda Bulunursun</p>
                    <span className='text'>Su tüketimini azaltarak su kaynaklarının sürdürülebilirliğine katkıda bulunabilirsin.</span>
                </div>
            </div>
            <div className='benefit-item'>
                <div className='image-container'>
                    <WaterConsumption className='image' />
                </div>
                <div className='description'>
                    <p className='title'>Evindeki Su Kullanımını Daha Net Görürsün</p>
                    <span className='text'>Uygulama sayesinde su tüketimini detaylı olarak görüp analiz edebilirsin.</span>
                </div>
            </div>
            <div className='benefit-item'>
                <div className='image-container'>
                    <WaterLeak className='image' />
                </div>
                <div className='description'>
                    <p className='title'>Evindeki Su Sızıntılarını Önlersin</p>
                    <span className='text'>Evindeki su kaçaklarını tespit ederek hepsini kontrol altına alabilirsin.</span>
                </div>
            </div>
        </div>
        </Container>
    </div>
);

export default BenefitContent;