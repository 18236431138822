import React from 'react';
import Container from '../container/container';

import './welcome-text.styles.scss';

const WelcomeText = () => (
    <Container>
        <div className='welcome-text-field'>
            <p className='title'>SUNA’ya hoş geldin!</p>
            <span className='text'>SUNA, su sıkıntısı yaşanan ülkemizde doğal çevrenin ve tüm canlıların kalıcı iyiliği için su kaynaklarımızın daha etkin kullanılmasını amaçlayan bir su tasarrufu uygulamasıdır.</span>
        </div>
    </Container>
);

export default WelcomeText;