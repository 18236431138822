import React from "react";

import './feature-item.styles.scss';


const FeatureItem = ({Image, title, text}) => (
    <div className='feature-item'>
        <Image className='image'/>
        <div className='feature-footer'>
            <span className='title'>{title}</span>
            <span className='text'>{text}</span>
        </div>
    </div>
);

export default FeatureItem;