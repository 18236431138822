import React from "react";

import './footer-truestmark.styles.scss';

const FooterTrustmark = () => (
    <div className="reserved-rights">
        <a className="eco-trustmark-icon" href="https://profiles.eco/suna?ref=tm" rel="noopener">
	        <img className="eco-trustmark" alt=".eco profile for suna.eco" src="https://trust.profiles.eco/suna/eco-mark.svg?color=%23017BEF"/>
        </a>
        &copy; 2022 Suna - Tüm Hakları Saklıdır
    </div>
);

export default FooterTrustmark;