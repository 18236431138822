import React from "react";
import Container from "../../components/container/container";

import './clarification-text-page.styles.scss';
import MobileDownload from "../../sections/mobile-download/mobile-download";

const ClarificationTextPage = () => (
    <div className="clarification-text-page">
        <Container>
            <div className="clarification-text-page-context">
                <h1>Aydınlatma Metni</h1>
                <div className="clarification-text-passage-context">
                    <div className="clarification-text-initial-passage">
                        <p className="passage-header">SOFTTECH YAZILIM TEKNOLOJİLERİ ARAŞTIRMA GELİŞTİRME VE
                            PAZARLAMA TİCARET ANONİM ŞİRKETİ KİŞİSEL VERİLERİNİN
                            KORUNMASI VE İŞLENMESİ AYDINLATMA METNİ</p>
                        <p className="initial-text">6698 sayılı Kişisel Verilerin Korunması Kanunu (<b>“Kanun”</b>) uyarınca, veri sorumlusu sıfatıyla
                            hareket eden Softtech Yazılım Teknolojileri Araştırma Geliştirme ve Pazarlama Ticaret
                            Anonim Şirketi (<b>“Şirket”</b>) tarafından kişisel verileriniz aşağıda açıklanan kapsamda
                            işlenebilecektir. </p>
                        <ol className="clarification-text-ordered-list">
                            <li className="clarification-text-ordered-list-item-header">Kişisel Verilerin İşlenme Amacı ve Hukuki Sebebi</li>
                            <p>
                                Toplanan kişisel veriler, Kanun’da öngörülen temel ilkelere uygun olarak ve Kanun’un 5.
                                maddesinde belirtilen kişisel veri işleme şartları dahilinde, aşağıdaki amaçlarla ve hukuki
                                sebeplere dayalı olarak işlenebilecektir:
                            </p>
                            <br/>
                            <table>
                                <tr>
                                    <th>İşlenen Kişisel Veri</th>
                                    <th>İşleme Amacı</th>
                                    <th>İşlemenin Hukuki Sebebi</th>
                                </tr>
                                <tr>
                                    <th>Ad, soyad, e-posta
                                        adresi, su faturası
                                        bilgileri (kurum,
                                        abone numarası)</th>
                                    <th>Uygulamaya login olunması ve
                                        kullanıcının profiliyle
                                        eşleştirilebilmesi</th>
                                    <th><b>İlgili kişinin temel hak ve
                                        özgürlüklerine zarar vermemek
                                        kaydıyla, veri sorumlusu olarak
                                        Şirketimizin meşru menfaatleri
                                        için veri işlenmesinin zorunlu
                                        olması nedeniyle açık rıza
                                        olmaksızın Kanun m.5/2
                                        uyarınca işleme</b></th>
                                </tr>
                            </table>
                            <br/>
                            <li className="clarification-text-ordered-list-item-header">Kişisel Verilerinizin Aktarımı ve Aktarım Amaçları</li>
                            <p>
                                Toplanan kişisel veriler; İşleme Amacının gerçekleştirilmesi doğrultusunda, kanunen yetkili
                                kamu kurumlarına ve kanunen yetkili özel kişilere Kanun’un 5. maddesinde düzenlenen veri
                                işleme şartları kapsamında Kanun’un 8. maddesi uyarınca aktarılabilecektir. <br />
                                <br/>Açık rıza vermeniz halinde Kanun’un 5. maddesinde düzenlenen veri işleme şartları
                                kapsamında toplanan kişisel veriler barındırma hizmeti alınması amacıyla yurtdışında mukim
                                Amazon Web Services’a Kanun’un 9. maddesinde belirtilen kişisel verilerin aktarılmasına
                                ilişkin kurallara uygun olarak aktarılabilecektir.
                            </p>
                            <li className="clarification-text-ordered-list-item-header">Kişisel Verilerinizin Toplamanın Yöntemi</li>
                            <p>
                                Kişisel verileriniz Şirketimiz tarafından elektronik ortamda çevrimiçi formlar vasıtasıyla işbu
                                Aydınlatma Metni’nin 1. Maddesinde yer verilen hukuki sebeplere dayalı olarak
                                toplanmaktadır.
                            </p>
                            <li className="clarification-text-ordered-list-item-header">Kişisel Verilerinize İlişkin Haklarınız</li>
                            <p className="clarification-text-ordered-list-item-header">İlgili kişi olarak Kanun’un 11. maddesi uyarınca aşağıdaki haklara sahip olduğunuzu
                                bildiririz:</p>
                            <ul>
                                <li>Kişisel verilerinizin işlenip işlenmediğini öğrenme,</li>
                                <li>Kişisel verileriniz işlenmişse buna ilişkin bilgi talep etme,</li>
                                <li>Kişisel verilerinizin işlenme amacını ve bunların amacına uygun kullanılıp
                                    kullanılmadığını öğrenme,</li>
                                <li>Yurt içinde veya yurt dışında kişisel verilerinizin aktarıldığı üçüncü kişileri bilme,</li>
                                <li>Kişisel verileriniz eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini
                                    isteme ve bu kapsamda yapılan işlemin kişisel verilerinizin aktarıldığı üçüncü kişilere
                                    bildirilmesini isteme,</li>
                                <li>İşlenen verilerinizin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi
                                    suretiyle aleyhinize bir sonucun ortaya çıkmasına itiraz etme,</li>
                                <li>Kişisel verilerinizin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması hâlinde
                                    zararın giderilmesini talep etme.</li>
                            </ul>
                            <p>Yukarıda sıralanan haklarınıza yönelik başvurularınızı, aşağıdaki adresler üzerinden
                                Şirketimize iletebilirsiniz:</p>
                            <ul>
                                <li><b>Yazılı Başvuru:</b> https://softtech.com.tr/wp-content/uploads/2018/07/KVKBa%C5%9Fvuru-Formu.pdf adresinde yer alan Kişisel Veri Başvuru Formumuzu
                                    doldurarak talebinizi İTÜ Ayazağa Kampüsü Reşitpaşa Mahallesi Katar Caddesi İTÜ
                                    Teknokent ARI-3 Binası No: 4 Kat: 4-5 34469 Maslak-Sarıyer/İstanbul adresine ıslak
                                    imzalı olarak elden teslim edebilir veya aşağıda yer alan KEP adresine güvenli
                                    elektronik imzalı olarak gönderebilirsiniz.</li>
                                <li><b>Kayıtlı Elektronik Posta (KEP) Adresi:</b> softtech@hs02.kep.tr</li>
                                <li><b>E-Posta Adresi:</b> Tarafınızca tarafımıza daha önce bir e-posta adresi bildirilmiş ve
                                    sistemimize kaydedilmiş ise bu kayıtlı bulunan elektronik posta adresini kullanmak
                                    suretiyle info@softtech.com.tr adresine de başvuru formunu iletebilirsiniz. (eğer
                                    gönderim yaptığınız e-posta adresi Şirketimizin sisteminde kayıtlı değilse,
                                    başvurunuzun güvenli elektronik imza ya da mobil imza ile imzalanması gerekmektedir)</li>
                            </ul>
                            <p>Talebinizde ilgili mevzuatın gerektirdiği kimlik bilgilerinizle birlikte kullanmak istediğiniz
                                hak/talep açıkça belirtilmelidir.</p>
                            <p>Tarafımıza iletilen ve mevzuatın gerektirdiği nitelikleri taşıyan talepler, talebin niteliğine göre
                                30 (otuz) gün içerisinde sonuçlandırılacak ve sonucu tarafınıza talebinizde seçmiş olduğunuz
                                geri bildirim usulüne uygun olarak yazılı veya elektronik ortamda bildirilecektir.</p>
                        </ol>
                    </div>
                </div>
                <MobileDownload />
            </div>
        </Container>
    </div>
);

export default ClarificationTextPage;