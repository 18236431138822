import React from "react";

import './footer.styles.scss';

import Container from '../../components/container/container';
import FooterLinkContent from "../../sections/footer-link-content/footer-link-content";
import FooterTrustmark from "../../sections/footer-trustmark/footer-truestmark";
import FooterAppInfo from "../../sections/footer-app-info/footer-app-info";

const Footer = () => (
    <div className="footer">
        <Container>
            <div className="footer-navigator">
                <FooterAppInfo />
                <FooterLinkContent />
            </div>
            <FooterTrustmark />
        </Container>
    </div>
);

export default Footer;