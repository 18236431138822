import React from 'react';

import './description-text.styles.scss';
import Container from '../container/container';
import MobileDownload from '../../sections/mobile-download/mobile-download';

const DescriptionText = () => (
    <div className='description-wrapper'>
       <Container>
            <div className='description-text-field'>
                <p className='title'>SUNA ile suyumuzu birlikte koruyalım</p>
                <span className='text'>Suna ile su tüketimini azaltarak dünyadaki susuzluk sorununun çözümüne katkıda bulunabilirsin. Daha az harca, daha az öde, daha iyi bir dünya bırak!</span>
                <MobileDownload />
            </div>
       </Container> 
    </div>
);

export default DescriptionText;