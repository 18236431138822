import React from "react";
import Container from "../../components/container/container";

import './user-guide-page.styles.scss';
import UserGuide from '../../assets/user-guide.png';
import MobileDownload from "../../sections/mobile-download/mobile-download";
const UserGuidePage = () => (
    <div className="user-guide-page">
        <Container>
            <div className="user-guide-page-context">
                <h1>Nasıl Çalışır?</h1>
                <div className="user-guide-passage-context">
                    <div className="user-guide-initial-passage">
                        <p className="initial-text">Uygulamayı indirdikten sonra, üyeliğinizi tamamlamak ve aktif bir kullanıcı olmak için su ayak izinizi hesaplamanız gerekmektedir. Su ayak izi, tüketilen temiz su ve kirletilen atık su olarak yaşamsal faaliyetlerin sonucunda ortaya çıkan su miktarlarının hesaplanmasıdır. Evinizdeki su tüketiminiz ile ilgili soruları yanıtladıktan sonra su ayak iziniz ton/gün olarak otomatik hesaplanır.</p>
                        <p className="initial-text">Bu hesaplamadan sonra uygulama ana ekranında yıllık toplam ve günlük kişi başı su tüketiminizi görebilirsiniz.</p>
                        <p className="initial-text">Ardından uygulama içerisinde size verilen görevleri tamamlayarak evinizdeki su tüketiminizi önemli ölçüde azaltabilirsiniz. Tamamladığınız her görev için damla puanlar kazanırsınız. Ayrıca görevler hakkında tavsiyelerde bulunabilir ve onları sosyal medyada paylaşabilirsiniz. Her paylaşımınız için ekstra damla puan kazanırsınız.</p>
                        <p className="initial-text">SUNA’yı kullanırken keyif alacak, hem bütçenize hem kuraklıkla mücadeleye katkıda bulunacaksınız.</p>
                    </div>
                    <img className="user-guide-image" alt="user-guide" src={UserGuide}/>
                </div>
                <MobileDownload />
            </div>
        </Container>
    </div>
);

export default UserGuidePage;

