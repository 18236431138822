import React from "react";

import './contact-page.styles.scss';
import ContactMap from '../../assets/contact-map.png';
import Container from "../../components/container/container";

const ContactPage = () => (
    <div className="contact-page">
        <Container>
            <div className="page-context">
                <h1 className="page-name">İletişim</h1>
                <div className="dotted-line"/>
                <div className="body-context">
                    <div className="passage">
                        <p className="contact-title">Adres</p>
                        <p className="contact-text">İTÜ Ayazağa Kampüsü Reşitpaşa Mah. Katar Cad. İTÜ Teknokent Arı-3 No:4 Kat:4-5, 34469 Maslak – Sarıyer</p>
                        <p className="contact-title">Telefon</p>
                        <p className="contact-text">+90 850 290 6000</p>
                        <p className="contact-title">Fax</p>
                        <p className="contact-text">+90 212 328 1199</p>
                        <p className="contact-title">E-Posta</p>
                        <p className="contact-text">info@suna.eco</p>
                    </div>
                    <div className="body-image">
                        <img className="contact-map-image" alt="contact-map" src={ContactMap}/>
                    </div>
                </div>
            </div>
        </Container>
    </div>
)

export default ContactPage;