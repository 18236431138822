import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import Container from '../../components/container/container';
import {ReactComponent as Logo} from './../../assets/logo.svg';
import './header.styles.scss';

const Header = (props) => {
    
    const organizeHeaderPosition = () => {

        const page = props.location.pathname;
        if(page ===  '/') {
            return {
                position: 'absolute'
            }
        }
        else {
            return {
                position: 'relative',
                background: 'transparent linear-gradient(94deg, #0839F4 0%, #0285F8 52%, #00BDF9 100%) 0% 0% no-repeat padding-box'
            }
        }
    }

    return (
        <div className='header' style={organizeHeaderPosition()} >
            <Container>
                <Link className='logo-container' to='/'>
                    <Logo className='logo' />
                </Link>
                <div className='options'>
                    <Link className='option' to='/suna-nedir'>
                    SUNA Nedir?
                    </Link>
                    <Link className='option' to='/nasil-calisir'>
                        Nasıl Çalışır?
                    </Link>
                    <a className='option' href="/iletisim">
                        İletişim
                    </a>
                </div>
            </Container>
        </div>
        )
}
export default withRouter(Header);