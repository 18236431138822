import './body-passage.styles.scss';

const BodyPassage = () => (
    <div className="secondary-passage">
        <p className="secondary-text">SUNA, su sıkıntısı yaşanan ülkemizde, doğal çevrenin ve tüm canlıların kalıcı iyiliği için su kaynaklarımızın daha etkin kullanılmasını sağlamayı ve su tasarrufu farkındalığı yaratmayı amaçlayan bir uygulamadır. Sadece ülkemizi değil, tüm dünyayı etkileyen küresel ısınma ve iklim krizinin neden olduğu kuraklığın önüne geçebilmek için bizlere düşen bireysel çabayı teşvik eder. </p>
        <p className="secondary-text">SUNA’yı aktif olarak kullanan, uygulama içinde yer alan su tasarrufu sağlayan tedbirleri uygulayan ve tasarruf tavsiyelerini dikkate alan kişiler, uygulama sayesinde evindeki su tüketimini en makul düzeye indirerek kuraklık ile mücadeleye ve daha sürdürülebilir bir yaşama katkıda bulunur. Ayrıca daha düşük su faturaları öder ve damla puanlar kazanır. </p>
        <p className="secondary-text">Su tasarrufu, geleceğe yapılan en büyük yatırımdır. SUNA sayesinde gelecek nesillere daha güzel bir dünya bırakmak hepimizin görevi. Sürdürülebilir bir geleceği önemsiyoruz. Siz de uygulamamızı indirerek buna katkıda bulunabilirsiniz.</p>
    </div>
);

export default BodyPassage;