import React from 'react';

import './social-media.styles.scss';

// import {ReactComponent as Instagram} from './../../assets/instagram.svg';
// // import {ReactComponent as Linkedin} from './../../assets/linkedin.svg';
import {ReactComponent as Twitter} from './../../assets/twitter.svg';
// import {ReactComponent as Facebook} from './../../assets/facebook.svg';

const SocialMedia = () => (
    <div className="social-media-icons">
        <a href='https://twitter.com/suna_eco'>
            <Twitter />
        </a>
    </div>
);

export default SocialMedia;