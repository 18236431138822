import React from "react";

import './definition-page.styles.scss';
import Container from "../../components/container/container";

import Hero from "./sections/hero/hero";
import BodyPassage from "./sections/body-passage/body-passage";
import MobileDownload from "../../sections/mobile-download/mobile-download";

const DefinitionPage = () => (
    <div className="definition-page">
        <Container>
            <div className="page-context">
                <h1 className="page-name">SUNA Nedir?</h1>
                <Hero />
                <div className="dotted-line"/>
                <BodyPassage />
                <MobileDownload />
            </div>
        </Container>
    </div>
);

export default DefinitionPage;