import React from "react";
import FooterLinkData from "./footer-links.data";
import './footer-link-content.styles.scss'
import FooterContactInfo from "../footer-contact-info/footer-contact-info";

class FooterLinkContent extends React.Component {
    constructor() {
        super();
        
        this.state = {
            columns: FooterLinkData
        }
    }

    render() {
        const { columns } = this.state;
        return (
            <div className="footer-link-content">
            {
                columns.map(({column_id, column_title, column_link, links}) => (
                    <div key={column_id} className="footer-links" >
                        <a className="column-title"  href={column_link}>{column_title}</a>
                        {
                            links.map(({id, title, path}) => (
                                <a key={id} className="page" href={path}>
                                {title}
                                </a>
                            ))
                        }
                    </div>
                ))
            }
            <FooterContactInfo />
            </div>
        )
    }
}

export default FooterLinkContent;