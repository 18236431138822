import { Route, Switch } from 'react-router-dom';
import './App.scss';
import Footer from './layouts/footer/footer';
import Header from './layouts/header/header';
import DefinitionPage from './pages/definition-page/definition-page';
import Homepage from './pages/homepage/homepage';
import UserGuidePage from './pages/user-guide-page/user-guide-page';
import ContactPage from './pages/contact-page/contact-page';
import ClarificationTextPage from './pages/clarification-text-page/clarification-text-page';

function App() {
  return (
    <div>
    <Header />
    <Switch>
      <Route exact path='/' component={Homepage} />
      <Route exact path='/suna-nedir' component={DefinitionPage} />
      <Route exact path='/nasil-calisir' component={UserGuidePage} />
      <Route exact path='/aydinlatma-metni' component={ClarificationTextPage} />
      <Route exact path='/iletisim' component={ContactPage} />
    </Switch>
    <Footer />
    </div>
  );
}

export default App;