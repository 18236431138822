import React from 'react';
import './homepage.styles.scss'

import DescriptionText from '../../components/description-text/description-text';
import WelcomeText from '../../components/welcome-text/welcome-text';
import FeatureList from '../../components/feature-list/feature-list';
import BenefitContent from '../../sections/benefit-content/benefit-content';

import WaterAnalysis from '../../assets/water-analysis.png';
import PhoneImage from '../../assets/phone-image.png';
import ProvinceSavingAnalytics from '../../sections/province-saving-analytics/province-saving-analytics';

const Homepage = () => (
    <div className='homepage' >
        <DescriptionText />
        <WelcomeText />
        <FeatureList />
        <BenefitContent />
        {/* <ProvinceSavingAnalytics /> */}
        <div className='world-risk'>
            <div className='description'>
                <p className='title'>Dünya Risk Altında</p>
                <span className='text'>Küresel olarak su tüketimi her geçen gün artıyor. Küresel ısınma nedeniyle iklim değişimi ve su kaynaklarının kirlenmesi, temiz su kaynaklarının giderek tükenmesine neden oluyor. Dünyadaki ülkelerin neredeyse üçte birinin suya erişimi oldukça kısıtlı.</span>
            </div>
            <img className='image' alt='water-analysis' src={WaterAnalysis}/>
        </div>
        <div className='bottom-content'>
            <div className='description'>
                <p className='title'>Peki Ne Yapabilirsin?</p>
                <span className='text'>Bu konuda sana çok iş düşüyor! Sadece kendin için değil, sonraki nesiller ve diğer canlılar için de su kaynaklarını bugünden daha tasarruflu kulanmak çok önemli! Bu yüzden günlük hayatında suyu daha verimli kullanarak su ayak izini azaltabilir ve gelecek nesillere daha iyi bir dünya hediye edebilirsin.</span>
            </div>
            <img className='image' alt='phoneImage' src={PhoneImage} />
        </div>
    </div>
)


export default Homepage;