import React from "react";

import './feature-list.styles.scss';
import Container from "../container/container";
import FeatureItem from "../feature-item/feature-item";
import FeatureListData from "./feature-list.data";

const FeatureList = () => {
    const featureList = FeatureListData;
    
    return (
        <div className='feature-list'>
            <Container>
                {
                    featureList.map(({id, ...feature}) => (
                        <FeatureItem key={id} {...feature} />
                    ))
                }
            </Container>
        </div>
    )
}

export default FeatureList;