const FooterLinkData = [
    {
        column_id: 1,
        column_title: "SUNA",
        links: [
            {
                id: 1,
                path: '/suna-nedir',
                title: 'Suna Nedir?'
            },
            {
                id: 3,
                path: '/nasil-calisir',
                title: 'Nasıl Çalışır?'
            }
        ]
    },
    {
        column_id: 2,
        column_title: "Kullanım Koşulları",
        column_link: "/kullanim-kosullari.pdf",
        links: [
            {
                id: 1,
                path: '/aydinlatma-metni',
                title: 'Aydınlatma Metni'
            },
            {
                id: 2,
                path: 'https://softtech.com.tr/web-sitesi-gizlilik-politikasi/',
                title: 'Gizlilik Politikası'
            },
            {
                id: 3,
                path: 'https://softtech.com.tr/wp-content/uploads/2018/07/KVK-Ba%C5%9Fvuru-Formu.pdf',
                title: 'KVKK Başvuru Formu'
            },
            {
                id: 4,
                path: '/cerez-politikasi.pdf',
                title: 'Çerez Politikası'
            }
        ]

    },
];

export default FooterLinkData;