import { ReactComponent as CalculateWFP} from '../../assets/calculate_water_foot_print.svg';
import { ReactComponent as CompleteMission} from '../../assets/complete_mission.svg';
import { ReactComponent as EarnSunaDrops} from '../../assets/earn_suna_drops.svg';
import { ReactComponent as SolutionThirsty} from '../../assets/solution_thirsty.svg';

const FeatureListData = [
    {
        id: 1,
        Image: CalculateWFP,
        title: "Su Ayak İzini Hesapla",
        text: "Su ayak izini hesaplayarak aylık su tüketimini öğren ve suyu tasarruflu kullan"
    },
    {
        id: 2,
        Image: CompleteMission,
        title: "Görevleri Tamamla",
        text: "Uygulama içerisinde verilen tasarruf görevlerini tamamla ve sosyal medyada paylaş"
    },
    {
        id: 3,
        Image: EarnSunaDrops,
        title: "SUNA Damlaları Kazan",
        text: "Her tamamlanan görevden damla kazan ve daha düşük fatura öde"
    }, 
    {
        id: 4,
        Image: SolutionThirsty,
        title: "Susuzluğa Çare Ol",
        text: "Uygulamayı sürekli kullanarak gelecek nesillere daha iyi bir dünya bırak"
    }
];

export default FeatureListData;