import Container from '../../components/container/container';
import './province-saving-analytics.scss';
import RankFirst from '../../assets/rank_first.png'
import RankSecond from '../../assets/rank_second.png'
import RankThird from '../../assets/rank_third.png';
import TurkeyMap from 'turkey-map-react';
import axios from 'axios';
import React from "react";

class ProvinceSavingAnalytics extends React.Component  {
    constructor(){
        super();
        this.state = {
            cities: [],
            tooltipText: "",
            first: "İSTANBUL",
            third: "ANKARA",
            second: "ANTALYA",
        }
    }
     componentDidMount() {
        axios.get(`${process.env.REACT_APP_API_URL}province/saving`, { headers: { 'x-api-key': 'apiKey'}}).then(x => {
            this.setState({ cities: x.data, first: x.data[0].name, second: x.data[1].name, third: x.data[2].name });
            console.log(x.data);
            x.data.forEach(a => {
                document.querySelector(`[data-plakakodu="${a.province_id}"]`).firstChild.style.setProperty('fill', '#017BEF', "important");
                document.querySelector(`[data-plakakodu="${a.province_id}"]`).firstChild.style.stroke="#017BEF";
                document.querySelector(`[data-plakakodu="${a.province_id}"]`).firstChild.style.setProperty("stroke-width", "2px");
                document.querySelector(`[data-plakakodu="${a.province_id}"]`).firstChild.style.setProperty('background-color', '#017BEF', "important");
            })
        }).catch(err => console.log(err));
    }

    colorUpCities = ({ plateNumber, name }) => {
        const city = this.state.cities.find(x => x.province_id === plateNumber);
        if(city) {
            document.querySelector(`[data-plakakodu="${plateNumber}"]`).firstChild.style.setProperty('fill', '#017BEF', "important");
            this.setState({ tooltipText: `${city.name} - ${city.total_saving}`});
        }
        else {
            this.setState({ tooltipText: `${name} - 0`});
        }
    }

    render() {
        return (
        <div className='province-saving-analytics'>
        <Container>
            <p className='title'>En Çok Su Tasarrufu Yapılan İllerimiz</p>
            <div  className='text-content'>
                <p className='main'>SUNA uygulamasındaki görevleri tamamlayarak su tasarrufu sağlayan kullanıcıların şehirlere göre dağılımı ve toplamda ne kadar su tasarrufu sağladıklarını aşağıda görebilirsiniz. Dağılım ve miktarlar her ay güncellenmektedir.</p>
                <p className='total-saving'>SUNA sayesinde bu ay toplam</p>
                    <p className='total-saving-amount'>1.867.432 ton</p>
                <p className='total-saving'>su tasarrufu yapıldı!</p>
            </div>
            <div className='rank-content'>
                <div className='rank-rate'>
                    <p className='rank-header'>{this.state.second}</p>
                    <img className='rank-icon' alt='rank-second' src={RankSecond} />
                </div>
                <div className='rank-rate'>
                    <p className='rank-header'>{this.state.first}</p>
                    <img className='rank-icon' alt='rank-first' src={RankFirst} />
                </div>
                <div className='rank-rate'>
                    <p className='rank-header'>{this.state.third}</p>
                    <img className='rank-icon' alt='rank-third' src={RankThird} />
                </div>
            </div>
            {/* <div className='turkey-map'>
                <TurkeyMap 
                    showTooltip={true}
                    tooltipText={this.state.tooltipText}
                    onHover={this.colorUpCities}
                    customStyle={{ idleColor: "#3AB6DC"}}
                />
            </div> */}
        </Container>
        </div>
    )
    }

}

export default ProvinceSavingAnalytics;