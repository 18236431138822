import React from "react";
import { Link } from 'react-router-dom';

import './footer-app-info.styles.scss';

import SocialMedia from "../../components/social-media/social-media";
import {ReactComponent as Logo} from './../../assets/logo.svg';

const FooterAppInfo = () => (
    <div className="app-info">
        <Link className='logo-container' to='/'>
            <Logo className='logo' />
        </Link>
        <p className="description">SUNA, bir su tasarruf farkındalığı uygulamasıdır.</p>
        <p className="email">info@suna.eco</p>
        <SocialMedia />
    </div>
);

export default FooterAppInfo;