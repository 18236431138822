import { Link } from 'react-router-dom';
import AppleStore from '../../assets/apple-store.png';
import PlayStore from '../../assets/play-store.png';

import './mobile-download.styles.scss';

const MobileDownload = () => (
    <div className='download-links'>
        <a href='https://apps.apple.com/tr/app/suna/id1592135614'>
        <img className='download-link' alt='AppleStore' src={AppleStore}/>
        </a>
        <a href='https://play.google.com/store/apps/details?id=com.softtech.sunaapp'>
        <img className='download-link' alt='PlayStore' src={PlayStore} />
        </a>
    </div>
);

export default MobileDownload;